<template>
  <div class="table-container biathlon-results">
    <table
      class="results-table table table_default"
      :class="{'results-table_openable' : isOpenable}"
    >
      <colgroup>
        <col width="1">
        <col width="300">
      </colgroup>
      <thead class="table__header">
      <tr>
        <th class="text-center" style="min-width: 0;">{{ $t('result.position') }} ({{ positionByText.toLowerCase() }})</th>
        <th class="member-td">{{ $t('result.participant') }}</th>
        <th>{{ $t('result.number') }}</th>
        <th>{{ $t('result.result') }}</th>
        <th
          v-for="(stage, i) in stages"
          :key="stage.id"
          class="text-xs text-center"
          :class="{ 'biathlon-results__circle-td': i % 2 === 0 }">
          <div v-if="stage.disciplineCode && i > 0">
            <p>
              {{ stage.name }}
            </p>
            <div class="biathlon-results__sub-th">
              <p>Штрафн. круги</p>
              <p>Результат на круге</p>
            </div>
          </div>
          <span v-else>
            {{ stage.name }}
          </span>
        </th>
        <th v-if="!hideCategory">{{ $t('result.category') }}</th>
      </tr>
      </thead>
      <tbody>
      <template
        v-for="(result, i) in results">
        <!-- Row : BEGIN -->
        <tr
          :key="i"
          :data-id="result.participantId"
          :class="{
            'table__highlight': openedResult === result.participantId,
            'table__highlight-border': idHighlighted === result.participantId,
          }"
          @click="isOpenable ? openResult(result.participantId) : null"
        >
          <td class="text-center">
            <ResultPosition
              class="text-lg font-monospace"
              :position="result[positionByKey]"
              :status="result.status"
              :leadersCount="absoluteLeadersCount"
            ></ResultPosition>
          </td>
          <td class="member-td">
            <Member
              :key="openedResult === result.participantId ? +new Date() : +new Date() + 1"
              :name="result.fullName"
              :img="result.avatarLargeImage && result.avatarLargeImage.replace('-x-', '112x112')"
              :imgSize="48"
              :nameTo="getDetailsLink(result.participantId)"
              @clickImg.prevent.stop="!hideCompareButton ? $emit('compare', result.participantId) : null"
            >
              <template v-if="!hideCompareButton" v-slot:action>
                <CompareButton
                  :active="compareList.indexOf(result.participantId) !== -1"
                  @toggle="$emit('compare', result.participantId)"
                ></CompareButton>
              </template>
              <div>
                <span class="text-with-icon text-xs">
                  <Icon
                    class="icon"
                    :name="result.gender === Gender.Male ? 'male-color' : 'female-color'"
                    size="s"
                  />
                  <span>
                    {{ result.gender === Gender.Male ? $tc('general.man') : $tc('general.woman') }}
                  </span>
                  <span v-if="result.age">
                    • {{ result.age }}&nbsp;{{ $tc('general.of_years', result.age) }}
                  </span>
                </span>
              </div>
              <span @click.stop="">
                <Link
                  v-if="!config.hideVideo && config.hasVideo && result.video && result.video.length"
                  class="mt-8 mr-4"
                  variant="secondary"
                  icon-left="video"
                  size="lg"
                  :title="$t('result.video')"
                  @click="$emit('openVideoFinish', result.video)"
                ></Link>
                <Link
                  v-if="!config.hideDiploma && result.status === ResultStatus.Finish"
                  class="mt-8 mr-4"
                  variant="secondary"
                  icon-left="solution"
                  size="lg"
                  :title="$t('result.certificate')"
                  :href="getDiplomaUrl(result.participantId)"
                  target="_blank"
                ></Link>
                <Link
                  v-if="!config.hidePhoto && config.photoUrlTemplate && result.status === ResultStatus.Finish"
                  class="mt-8 mr-4"
                  variant="secondary"
                  icon-left="camera"
                  size="lg"
                  :title="$t('result.photos')"
                  :href="getPhotoUrl(result.number)"
                  target="_blank"
                ></Link>
              </span>
            </Member>
          </td>
          <td class="text-nowrap">
            № <span class="text-lg font-monospace">{{ result.number }}</span>
          </td>
          <td
            class="text-center font-monospace">
            <TimeOffset
              v-if="result.timeOffset && result.timeOffset !== '00:00'"
              :offset="result.timeOffset"
              :result="result.individualResult"
            ></TimeOffset>
            <span class="text-lg v-align-middle font-monospace">{{ result.resultByCalcMethod || '--' }}</span>
            <div class="text-sm color-muted mt-4 font-monospace" v-if="result.delayByCalcMethod">
              +{{ result.delayByCalcMethod }}
            </div>
          </td>
          <td
            v-for="(stage, i) in result.stageResults"
            :key="stage.stageId"
            class="text-center font-monospace"
            :class="{ 'biathlon-results__circle-td': i % 2 === 0 }">
            <div
              class="biathlon-results__penalty"
              v-if="stage.disciplineCode && i > 0">
              <div>
                <PenaltyCount :count="stage.penaltyAmount" />
              </div>
              <p class="biathlon-results__time">
                {{ stage.individualResult || '--' }}
              </p>
            </div>
            <p
              class="biathlon-results__time"
              v-else>
              {{ stage.individualResult || '--' }}
            </p>
          </td>
          <td v-if="!hideCategory">
            <div class="text-lg" style="max-width: 200px;">{{ result.genderAgeNominationName || '--' }}</div>
          </td>
        </tr>
        <!-- Row : END -->
        <!-- Details: BEGIN -->
        <tr
          v-if="isOpenable"
          :key="`${i}_details`"
          class="bg-light"
          v-show="openedResult === result.participantId"
        >
          <td colspan="100">
            <div class="results-table__details">
            <div class="block block_wide p-0">
              <div class="block__group">
                <Row>
                  <Col size="stretch">
                    <Member
                      v-if="openedResult === result.participantId"
                      :name="result.fullName || result.teamName"
                      :img="result.avatarLargeImage && result.avatarLargeImage.replace('-x-', '112x112')"
                      :imgSize="56"
                      :imgTo="getDetailsLink(result.participantId)"
                      :nameTo="getDetailsLink(result.participantId)"
                    >
                      <template v-slot:name="">
                        <div class="text-lg"><b>{{ result.fullName || result.teamName }}</b></div>
                      </template>
                      <div class="text-with-icon mt-4" v-if="result.city || result.countryCode">
                        <Icon class="icon color-muted" name="environment" size="s"></Icon>
                        <span class="text-sm">
                          {{ formatLocation(result.city, result.countryCode) }}
                        </span>
                      </div>
                    </Member>
                  </Col>
                  <Col size="auto">
                    <Button
                      tag="router-link"
                      variant="secondary"
                      size="sm"
                      :text="$t('result.detail_page')"
                      :to="getDetailsLink(result.participantId)"
                    ></Button>
                  </Col>
                </Row>
              </div>
              <div
                class="block__group section-block__group_bordered-top"
                v-if="result[stagesKey] && result[stagesKey].length">
                <table
                  class="table table_default biathlon-results__table-detailed">
                  <thead class="biathlon-results__table-header">
                  <tr>
                    <th>{{ $t('result.stage') }}</th>
                    <th class="text-center">{{ $t('result.result') }}</th>
                    <th class="text-center">{{ $t('biathlon.penalty_circles') }}</th>
                    <th class="text-center">{{ $t('result.speed') }}</th>
                    <th class="text-center">{{ $t('result.position') }} ({{ $t('result.position_cat') }})</th>
                    <th class="text-center">{{ $t('result.position') }} ({{ $t('result.position_gen') }})</th>
                    <th class="text-center">{{ $t('result.position') }} ({{ $t('result.position_abs') }})</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr
                    v-for="(stage, i) in result[stagesKey]"
                    :key="stage.stageId">
                    <td>
                      <div class="stage-name">
                        <div
                          class="stage-name__icon"
                          :class="`bg-${stage.disciplineCode}`">
                          <Icon
                            v-if="stage.disciplineCode"
                            :name="stage.disciplineCode"
                            size="l"
                          ></Icon>
                          <p v-else>
                            О
                          </p>
                        </div>
                        <div class="stage-name__text text-lg text-semibold">
                          <b>{{ stage.name }}</b>
                        </div>
                      </div>
                    </td>
                    <td class="text-center font-monospace">
                      <p
                        class="text-lg v-align-middle"
                        :class="{ 'mb-0-25': stage.absoluteResult }">
                        {{ config.calcMethod === 0 ? stage.individualResult : stage.absoluteResult || '--' }}
                      </p>
                      <p class="text-sm color-black-45 text-center">
                        {{ config.calcMethod === 0 ? stage.absoluteResult : stage.individualResult }}
                      </p>
                    </td>
                    <td class="text-center font-monospace">
                      <PenaltyCount
                        class="mlr-auto"
                        :count="stage.penaltyAmount"
                        v-if="stage.disciplineCode && i > 0 && stage.individualResult" />
                      <span
                        v-else
                        class="text-lg">--</span>
                    </td>
                    <td class="text-center font-monospace">
                      <span
                        class="text-lg"
                        v-html="formatPaceText(stage.speed) || '--'"
                      ></span>
                    </td>
                    <td class="text-center font-monospace">
                      <span class="text-lg">{{ stage.genderAgePosition || '--' }}</span>
                    </td>
                    <td class="text-center font-monospace">
                      <span class="text-lg">{{ stage.genderPosition || '--' }}</span>
                    </td>
                    <td class="text-center font-monospace">
                      <span class="text-lg">{{ stage.position || '--' }}</span>
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            </div>
          </td>
        </tr>
        <!-- Details: END -->
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>
import PenaltyCount from '@/components/PenaltyCount/PenaltyCount.vue';
import resultsMixin from '../resultsMixin';

export default {
  name: 'BiathlonResults',
  components: {
    PenaltyCount,
  },
  mixins: [resultsMixin],
  props: {
    isOpenable: Boolean,
    showCheckpoints: Boolean,
    hideCompareButton: Boolean,
  },
  data() {
    return {
      stagesKey: 'stageResults',
    };
  },
  methods: {
    getDetailsLink(id) {
      const route = this.$router.resolve({
        name: 'Participant',
        params: { participantId: id },
      });
      return route.href;
    },
  },
};
</script>

<style scoped lang="scss">
@import './BiathlonResults.scss';
</style>
