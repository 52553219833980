<template>
  <div class="penalty-count">
    <Icon
      class="penalty-count__icon"
      size="xxl"
      name="undo"/>
    <p class="penalty-count__value text-lg">
      {{ count }}
    </p>
  </div>
</template>

<script>
import Icon from '@rr-component-library/icon/src/main';

export default {
  name: 'PenaltyCount',
  components: {
    Icon,
  },
  props: {
    count: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
@import './PenaltyCount.scss';
</style>
